import images from '../images';
import icons from '../icons';
import './pages.css';

const Power = () => {
    return (
        <>
            <section>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '6rem' }}>
                    <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                        <div>
                            <h1 style={{ margin: '20px 0' }} className='different large-text'>
                                Energy Management
                            </h1>
                            <h2>Efficient Energy Management through Power Consumption Tracking</h2>
                        </div>
                        <img style={{ height: '50%', aspectRatio: '1 / 1' }} alt='' src={icons.power2} />
                    </div>
                    <h1 className='different'>Track Power Consumption for Optimal Efficiency</h1>
                    <img style={{ width: '60%' }} alt='' src={images.room} />
                    <p style={{ width: '60%', fontSize: 20 }}>
                        At<strong className='different'> JSR AL SAMA, </strong>
                        we recognize the importance of efficient energy
                        management in today’s dynamic business environment. Our innovative
                        solutions empower businesses to track power consumption effectively,
                        enabling them to make informed decisions and optimize energy usage.
                    </p>
                </div>
            </section>
            <section className='dark-section'>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '4rem', marginTop: 20 }}>
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Overview</h2>
                        <p>Our solution focuses on tracking power consumption across various sectors, from
                            commercial buildings to industrial facilities. By monitoring energy usage in realtime, our clients gain valuable insights into their energy consumption patterns,
                            allowing them to identify opportunities for optimization and cost savings.</p>
                    </div>
                    <img style={{
                        animationName: 'fade', width: '70%', alignSelf: 'center',
                        filter: 'contrast(150%) brightness(120%)'
                    }} alt='' src={images.powerOverview} />
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Implementation</h2>
                        <p>Our customized hardware setups are seamlessly integrated into existing
                            infrastructure, allowing for easy deployment and minimal disruption. Through
                            wireless connectivity and cloud-based platforms, data is securely transmitted
                            and accessible from anywhere, ensuring convenience and flexibility.</p>
                    </div>
                </div>
            </section>
            <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
                <h1 style={{ alignSelf: 'flex-start' }}>Services</h1>
                <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                    <div>
                        <h1 style={{ margin: '20px 0' }} className='different large-text'>
                            Energy Management
                        </h1>
                        <h2>Efficient Energy Management through Power Consumption Tracking</h2>
                    </div>
                    <img style={{ height: '50%', aspectRatio: '1 / 1' }}
                        alt='' src={icons.power2} />
                </div>
                <img style={{ width: '90%', filter: 'contrast(120%) brightness(120%)', margin: '20px 0' }}
                    alt='' src={images.tabManagement} />
                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                    <h2 className='different'>User Interface</h2>
                    <p>
                        Clients have access to user-friendly interfaces and dashboards, which display
                        real-time and historical data on power consumption. Customizable reporting
                        features enable stakeholders to track key performance indicators and monitor
                        energy efficiency initiatives effectively.</p>
                </div>
                <div className='section-head'>
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Customization</h2>
                        <p>
                            We understand that each client’s energy
                            management needs are unique. Therefore,
                            we offer customizable solutions tailored to
                            specific requirements, whether it’s integration
                            with existing infrastructure or additional
                            features to address unique challenges.</p>
                    </div>
                    <img alt='' src={icons.customization2} />
                </div>
            </section>
            <section className='dark-section over'>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '4rem' }}>
                    <h1 className='different' style={{ fontSize: 32 }}>
                        Technology Stack
                    </h1>
                    <p>Integrated data logging and visualization tools provide users
                        with clear insights into energy usage trends and patterns.</p>
                    <div className='section-content' style={{
                        backgroundColor: 'var(--bg-secondary)',
                        padding: 24, borderRadius: 8
                    }}>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2 className='different'>Expert Support</h2>
                            <p style={{ lineHeight: 1.5, fontSize: 18 }}>
                                Our team of experienced professionals
                                provides comprehensive support
                                throughout the implementation process,
                                from initial assessment and design to
                                ongoing maintenance and optimization.</p>
                        </div>
                        <img style={{ borderRadius: 8 }} alt='' src={icons.support} />
                    </div>
                    <div className='label-frame' style={{ gap: 12 }}>
                        <h1 style={{ backgroundColor: 'var(--bg)', color: 'var(--fg)' }}>Benefits</h1>
                        <div className='label-frame-part'
                            style={{ alignItems: 'center', borderRight: '2px solid var(--fg)', flex: .5 }}>
                            <img alt='' src={icons.wallet} />
                            <img alt='' src={icons.environment} />
                            <img alt='' src={icons.eyeSearch} />
                            <img alt='' src={icons.report} />
                        </div>
                        <div className='label-frame-part' style={{ paddingLeft: 12 }}>
                            <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                <h2 className='different'>Cost Savings</h2>
                                <p style={{ lineHeight: 1.5, fontSize: 18 }}>
                                    By identifying energy wastage and
                                    inefficiencies, our solution helps reduce
                                    utility bills and operational costs.</p>
                            </div>
                            <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                <h2 className='different'>Environmental Sustainability</h2>
                                <p style={{ lineHeight: 1.5, fontSize: 18 }}>
                                    Optimizing energy usage contributes to
                                    a reduced carbon footprint and supports
                                    sustainability goals.</p>
                            </div>
                            <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                <h2 className='different'>Operational Efficiency</h2>
                                <p style={{ lineHeight: 1.5, fontSize: 18 }}>
                                    Enhanced visibility into energy
                                    consumption patterns allows for
                                    proactive maintenance and optimization
                                    of equipment and systems.</p>
                            </div>
                            <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                <h2 className='different'>Compliance and Reporting</h2>
                                <p style={{ lineHeight: 1.5, fontSize: 18 }}>
                                    Our solution facilitates compliance with
                                    regulatory requirements and supports
                                    reporting obligations related to energy
                                    usage and conservation efforts.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Power;