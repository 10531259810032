import app from './icons/application.png';
import central from './icons/central.png';
import chlorine from './icons/chlorine.png';
import chlorine2 from './icons/chlorine-secondary.png';
import comfortable from './icons/comfortable.png';
import conditioner from './icons/conditioner.png';
import correct from './icons/correct.png';
import customization from './icons/customization.png';
import customization2 from './icons/customization-filled.png';
import drop from './icons/drop.png';
import environment from './icons/environment.png';
import eye from './icons/eye.png';
import eyeSearch from './icons/eye-search.png';
import feedback from './icons/feedback.png';
import focus from './icons/focus.png';
import gateway from './icons/gateway.png';
import gauge from './icons/gauge.png';
import gauge2 from './icons/gauge-secondary.png';
import hardware from './icons/hardware.png';
import hardware2 from './icons/hardware-secondary.png';
import innovation from './icons/innovation.png';
import integrity from './icons/integrity.png';
import middleware from './icons/middleware.png';
import power from './icons/power.png';
import power2 from './icons/power-secondary.png';
import project from './icons/project.png';
import quality from './icons/quality.png';
import reader from './icons/reader.png';
import remote from './icons/remote.png';
import remote2 from './icons/remote-secondary.png';
import report from './icons/report.png';
import security from './icons/security.png';
import software from './icons/software.png';
import solution from './icons/solution.png';
import support from './icons/support.png';
import sustainability from './icons/sustainability.png';
import system from './icons/system.png';
import system2 from './icons/system-secondary.png';
import tag from './icons/tag.png';
import thermometer from './icons/thermometer.png';
import visibility from './icons/visibility.png';
import wallet from './icons/wallet.png';
import wallet2 from './icons/wallet-secondary.png';
import wifi from './icons/wifi.png';

const icons = {
    app,
    central,
    chlorine,
    chlorine2,
    comfortable,
    conditioner,
    correct,
    customization,
    customization2,
    drop,
    environment,
    eye,
    eyeSearch,
    feedback,
    focus,
    gateway,
    gauge,
    gauge2,
    hardware,
    hardware2,
    innovation,
    integrity,
    middleware,
    power,
    power2,
    project,
    quality,
    reader,
    remote,
    remote2,
    report,
    security,
    software,
    solution,
    support,
    sustainability,
    system,
    system2,
    tag,
    thermometer,
    visibility,
    wallet,
    wallet2,
    wifi,
};

export default icons;