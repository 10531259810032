import acgm from './images/acgm.png';
import barcode from './images/barcode.png';
import corridor from './images/corridor.png';
import dashboard from './images/dashboard.png';
import deal from './images/deal.png';
import desk from './images/desk.png';
import development from './images/development.png';
import engMazen from './images/eng-mazen.png';
import group from './images/group.png';
import hall from './images/hall.png';
import hardware from './images/hardware.png';
import hospital from './images/hospital.png';
import hospitalRoom from './images/hospital-room.png';
import laboratory from './images/laboratory.png';
import logo from './images/logo.png';
import ECASLogo from './images/ecas.jpg';
import ROHSLogo from './images/rohs.jpg';
import TUVLogo from './images/tuv.png';
import rLogo from './images/r-logo.png';
import bLogo from './images/b-logo.png';
import management from './images/management.png';
import ministry from './images/ministry.png';
import missionStatement from './images/mission-statement.png';
import monitor from './images/monitor.png';
import monitorLight from './images/monitor-light.png';
import monitorOverview from './images/monitor-overview.png';
import office from './images/office.png';
import powerOverview from './images/power-overview.png';
import processOverview from './images/process-overview.png';
import processOverview2 from './images/process-overview-2.png';
import remoteControl from './images/remote-control.png';
import room from './images/room.png';
import sections from './images/sections.png';
import signature from './images/signature.png';
import swimmingPool from './images/swimming-pool.png';
import tabManagement from './images/tab-management.png';
import visionStatement from './images/vision-statement.png';

const images =  {
    acgm,
    barcode,
    corridor,
    dashboard,
    deal,
    desk,
    development,
    engMazen,
    group,
    hall,
    hardware,
    hospital,
    hospitalRoom,
    laboratory,
    logo,
    rLogo,
    bLogo,
    ECASLogo,
    ROHSLogo,
    TUVLogo,
    management,
    ministry,
    missionStatement,
    monitor,
    monitorLight,
    monitorOverview,
    office,
    powerOverview,
    processOverview,
    processOverview2,
    remoteControl,
    room,
    sections,
    signature,
    swimmingPool,
    tabManagement,
    visionStatement
};

export default images;