/* eslint-disable react/jsx-no-target-blank */
import images from "../images";
import WhatsApp from '@mui/icons-material/WhatsApp';
import Call from '@mui/icons-material/CallEndOutlined';
import Email from '@mui/icons-material/EmailOutlined';
import Website from '@mui/icons-material/LanguageOutlined';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-row">
                <div className="contact-info">
                    <img alt="logo" src={images.bLogo} />
                    <h3>United Arab Emirates</h3>
                    <h3>Dubai</h3>
                    <div>
                        <span title="Phone"><Call /> +971 55 469 6736</span>
                        <a title="WhatsApp" href="https://wa.me/+971581149388" target="_blank">
                            <WhatsApp /> +971 58 114 9388
                        </a>
                        <a title="Mail" href="mailto:info@sama-tech.ae">
                            <Email /> info@sama-tech.ae
                        </a>
                        <a title="Website" href="http://www.sama-tech.ae">
                            <Website /> www.sama-tech.ae
                        </a>
                    </div>
                </div>
                <img className="barcode" alt="barcode" src={images.barcode} />
            </div>
            <div className="footer-row second">
                <div className="section-icon">
                    <img alt="" src={images.ECASLogo} />
                    <p style={{ width: '100%' }}>ECAS (Emirates Conformity Assessment)</p>
                </div>
                <div className="section-icon">
                    <img alt="" src={images.ROHSLogo} />
                    <p style={{ width: '100%' }}>RoHS (Restriction of Hazardous Substances)</p>
                </div>
                <div className="section-icon">
                    <img style={{ borderRadius: 28 }} alt="" src={images.TUVLogo} />
                    <p style={{ width: '100%' }}>TÜV Rheinland Certified</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;