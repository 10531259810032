import Moon from '@mui/icons-material/DarkMode';
import Circle from '@mui/icons-material/Circle';
import Square from '@mui/icons-material/Square';
import CircleOutlined from '@mui/icons-material/CircleTwoTone';
import images from '../images';
import icons from '../icons';
import './pages.css';

const Acgms = () => {
    return (
        <>
            <section>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '6rem' }}>
                    <div>
                        <h1 style={{ margin: '20px 0' }} className='different large-text'>ACGMS</h1>
                        <h2>Air Conditioning and Gas Management System</h2>
                    </div>
                    <img style={{ width: '60%' }} alt='' src={images.management} />
                    <p style={{ width: '60%', fontSize: 20 }}>The <span className='different'>ACGMS</span> is a cutting-edge system designed to control and monitor air
                        conditioning units, humidity levels, and air quality. The system has been
                        implemented successfully in various buildings.</p>
                </div>
            </section>
            <section className='dark-section'>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column' }}>
                    <h1 style={{ margin: '20px 0' }} className='different large-text'>ACGMS</h1>
                    <img style={{
                        animationName: 'fade', width: '70%', alignSelf: 'center',
                        filter: 'contrast(150%) brightness(120%)'
                    }} alt='' src={images.acgm} />
                </div>
            </section>
            <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
                <h1 style={{ alignSelf: 'flex-start' }}>Services</h1>
                <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                    <div>
                        <h1 style={{ margin: '20px 0' }} className='different large-text'>ACGMS</h1>
                        <h2>Air Conditioning and Gas Management System</h2>
                    </div>
                    <img style={{ height: '50%', aspectRatio: '1 / 1' }} alt='' src={icons.system2} />
                </div>
                <img style={{ width: '90%', filter: 'contrast(120%) brightness(120%)' }}
                    alt='' src={images.dashboard} />
                <div className='label-frame'>
                    <h1>Key System Details</h1>
                    <div className='label-frame-part'>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2>Intelligent Electricity Optimization</h2>
                            <p>Deactivates AC during non-working
                                hours, preserving humidity levels
                                and extending unit lifespan.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2>Maintenance Tracking</h2>
                            <p>Monitors corrective and preventive
                                maintenance activities, highlighting
                                malfunctions and restoration times</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2>Flexible Scheduling</h2>
                            <p>Allows easy activation or deactivation
                                based on specific needs, ensuring
                                efficient energy usage.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2>Intelligent Control Based on Environmental Factors</h2>
                            <p>Our systems give control orders based on
                                real-time humidity or gas levels, ensuring
                                the environment remains safe and
                                comfortable while optimizing energy use.</p>
                        </div>
                    </div>
                    <div className='label-frame-part'>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2>User-Friendly Interface</h2>
                            <p>Accessible via computer or mobile
                                devices, offering convenient
                                monitoring and control options</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2>Accurate Sensor Readings</h2>
                            <p>Provides precise sensor readings
                                graphically or numerically for
                                comprehensive data analysis.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2>Adjustable Temperature Tolerance</h2>
                            <p>Tailor the temperature settings to your
                                specific comfort needs, allowing for
                                flexibility and personalized comfort
                                without compromising energy efficiency.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2>Customization Options</h2>
                            <p>Flexibility for hardware or software
                                customization to meet client-specific
                                and operational requirements.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='dark-section compare'>
                <h1>Services</h1>
                <h1 className='different large-text' style={{ marginBottom: 40 }}>Benefits around the clock</h1>
                <div className='section-head' style={{ gap: '2rem' }}>
                    <div className='label-frame' style={{ flex: 1, paddingTop: '8rem' }}>
                        <h1 className='framed-title' style={{
                            borderColor: 'var(--success)',
                            backgroundColor: 'var(--bg)',
                            color: 'white',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>On The Clock</h1>
                        <div className='label-frame-part'>
                            <div className='label-frame-field' style={{ paddingTop: '4rem' }}>
                                <h1 style={{
                                    display: 'flex', alignItems: 'center', gap: 8, zIndex: 1,
                                    backgroundColor: 'var(--bg)', color: 'white'
                                }}>
                                    <Circle style={{ fontSize: 48 }} />
                                    <p>
                                        <span className='different' style={{ fontSize: 28, fontWeight: 500 }}>
                                            During</span>
                                        <span style={{ display: 'block', fontSize: 14 }}>Working Hours</span>
                                    </p>
                                </h1>
                                <div className='section-content'>
                                    <img alt='' src={icons.conditioner} />
                                    <div className='section-content' style={{ userSelect: 'none' }}>
                                        <img alt='' src={icons.thermometer} />
                                        <sup style={{ fontSize: 18, fontWeight: 700 }}>°C</sup>
                                    </div>
                                </div>
                                <p style={{ marginTop: 12, fontWeight: 500 }}>The air conditioner focuses on
                                    <span className='different'> temprature control</span>
                                </p>
                            </div>
                            <div className='label-frame-field'>
                                <div className='section-content'>
                                    <img alt='' src={icons.conditioner} />
                                    <div className='section-content' style={{ userSelect: 'none' }}>
                                        <img alt='' src={icons.thermometer} />
                                        <sup style={{ fontSize: 10, fontWeight: 500 }}>ON<br />OFF</sup>
                                    </div>
                                </div>
                                <p style={{ marginTop: 12, fontWeight: 500 }}>
                                    Operating and stopping the air conditioning system based on
                                    <span style={{ color: '#E9983B' }}> the temprature</span>
                                </p>
                            </div>
                            <div className='label-frame-field'>
                                <div className='section-content'>
                                    <img alt='' src={icons.comfortable} />
                                </div>
                                <p style={{ marginTop: 12, fontSize: 32, fontWeight: 500 }}>
                                    <span style={{ display: 'flex', alignItems: 'center', gap: 12, marginTop: 8 }}>
                                        <img alt='' src={icons.correct} />Comfortable working environment
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <h1 style={{ fontSize: 48, fontWeight: 500 }}>VS</h1>
                    <div className='label-frame' style={{ flex: 1, paddingTop: '8rem' }}>
                        <h1 className='framed-title' style={{
                            borderColor: 'var(--danger)',
                            backgroundColor: 'var(--bg)',
                            color: 'white',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>Off The Clock</h1>
                        <div className='label-frame-part'>
                            <div className='label-frame-field' style={{ paddingTop: '4rem' }}>
                                <h1 style={{
                                    display: 'flex', alignItems: 'center', gap: 8, zIndex: 1,
                                    backgroundColor: 'var(--bg)', color: 'white'
                                }}>
                                    <Moon style={{ fontSize: 48 }} />
                                    <p>
                                        <span className='different' style={{ fontSize: 28, fontWeight: 500 }}>
                                            Outside</span>
                                        <span style={{ display: 'block', fontSize: 14 }}>Working Hours</span>
                                    </p>
                                </h1>
                                <div className='section-content'>
                                    <img alt='' src={icons.conditioner} />
                                    <div className='section-content' style={{ userSelect: 'none' }}>
                                        <img alt='' src={icons.drop} />
                                        <sup style={{ fontSize: 18, fontWeight: 700 }}>%</sup>
                                    </div>
                                </div>
                                <p style={{ marginTop: 12, fontWeight: 500 }}>The air conditioner focuses on
                                    <span className='different'> humidity control</span>
                                </p>
                            </div>
                            <div className='label-frame-field'>
                                <div className='section-content'>
                                    <img alt='' src={icons.conditioner} />
                                    <div className='section-content' style={{ userSelect: 'none' }}>
                                        <img alt='' src={icons.drop} />
                                        <sup style={{ fontSize: 10, fontWeight: 500 }}>ON<br />OFF</sup>
                                    </div>
                                </div>
                                <p style={{ marginTop: 12, fontWeight: 500 }}>The emphasis is on
                                    operating and stopping the air conditioner according
                                    <span style={{ color: '#E9983B' }}> to the humidity levels</span></p>
                            </div>
                            <div className='label-frame-field'>
                                <div className='section-content'>
                                    <img alt='' src={icons.conditioner} />
                                </div>
                                <p style={{ marginTop: 12, fontWeight: 500 }}>
                                    <span style={{ display: 'flex', alignItems: 'center', gap: 12, marginTop: 8 }}>
                                        <img alt='' src={icons.correct} />Extend life p of the AC
                                    </span>
                                    <span style={{ display: 'flex', alignItems: 'center', gap: 12, marginTop: 8 }}>
                                        <img alt='' src={icons.correct} />Maintaining the facility
                                    </span>
                                    <span style={{ display: 'flex', alignItems: 'center', gap: 12, marginTop: 8 }}>
                                        <img alt='' src={icons.correct} />Energy saving
                                    </span>
                                    <span style={{ display: 'flex', alignItems: 'center', gap: 12, marginTop: 8 }}>
                                        <img alt='' src={icons.correct} />Cost saving
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '6rem' }}>
                    <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                        <h1 style={{ margin: '20px 0' }} className='different'>
                            ACGMS and Gas Monitoring System Implementation Goals for Schools and Education Sector
                        </h1>
                        <img style={{ height: '50%', aspectRatio: '1 / 1' }} alt='' src={icons.system2} />
                    </div>
                    <img style={{ width: '60%' }} alt='' src={images.desk} />
                    <div className='section-icon' style={{ alignItems: 'flex-start', width: '60%' }}>
                        <h2 className='different'>Optimized Learning Environment</h2>
                        <p style={{ lineHeight: 1.5, fontSize: 18 }}>Deploy advanced climate and air quality management systems to ensure
                            a comfortable, healthy learning atmosphere, leading to better educational
                            outcomes.</p>
                    </div>
                    <div className='section-icon' style={{ alignItems: 'flex-start', width: '60%' }}>
                        <h2 className='different'>Energy Efficiency and Sustainability</h2>
                        <p style={{ lineHeight: 1.5, fontSize: 18 }}>Utilize intelligent ACGMS to enhance energy conservation, reduce
                            operational costs, and promote environmental responsibility within
                            educational institutions.</p>
                    </div>
                </div>
            </section>
            <section className='dark-section frame-over'>
                <h1>Services</h1>
                <div className='section-icon' style={{ marginTop: 20, gap: 30 }}>
                    <div style={{ flex: 1, width: '80%' }} className='label-frame'>
                        <div className='label-frame-part'>
                            <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                <h2>Safety and Air Quality</h2>
                                <p>Implement comprehensive gas
                                    monitoring solutions to safeguard
                                    against harmful emissions, aligning with health safety standards
                                    and improving school-wide wellbeing.</p>
                            </div>
                            <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                <h2>System Integration and Management</h2>
                                <p>Seamlessly integrate ACGMS with
                                    existing infrastructure for centralized control, promoting efficiency
                                    and ease of maintenance.</p>
                            </div>
                        </div>
                        <div className='label-frame-part'>
                            <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                <h2>Regulatory Compliance</h2>
                                <p>Ensure all facilities meet environmental regulations and achieve
                                    sustainability goals through data-driven ACGMS and gas monitoring strategies.</p>
                            </div>
                            <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                <h2>Educational Engagement</h2>
                                <p>Leverage technology to educate
                                    students on sustainability and
                                    stewardship, using real-time data
                                    to demonstrate the impact of
                                    environmental management in
                                    schools.</p>
                            </div>
                        </div>
                    </div>
                    <img style={{ width: '100%' }} alt='' src={images.hall} />
                </div>
            </section>
            <section className='compare'>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '2rem' }}>
                    <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                        <h1 style={{ margin: '20px 0' }} className='different'>
                            Gas Monitoring and  Control System
                        </h1>
                        <img style={{ height: '50%', aspectRatio: '1 / 1' }} alt='' src={icons.system2} />
                    </div>
                    <div className='section-icon' style={{ alignItems: 'flex-start', width: '60%' }}>
                        <h2>Introduction to Our Gas Monitoring Solution</h2>
                        <p style={{ lineHeight: 1.5, fontSize: 18 }}>In today’s healthcare environment, ensuring the safety and wellbeing of patients
                            and staff is paramount. Our Gas Monitoring and Control System is engineered
                            to provide comprehensive monitoring of gas levels across various departments
                            within healthcare facilities. It leverages advanced sensor technology to detect
                            a wide range of gases, offering real-time insights and alerts to maintain a safe
                            environment.</p>
                    </div>
                    <div className='section-head' style={{ gap: 12, alignItems: 'stretch' }}>
                        <div className='label-frame' style={{ flex: 1 }}>
                            <h1>Key Features</h1>
                            <div className='label-frame-part'>
                                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                    <h2 className='different'>Real-Time Gas Level Monitoring</h2>
                                    <p>Continuous surveillance of gas
                                        concentrations, including <strong>
                                            Carbon
                                            Dioxide (CO2), Formaldehyde
                                            (CH2O), Total Volatile Organic
                                            Compounds (TVOC), Ozone (O3),
                                            Carbon Monoxid e (CO), Nitrogen
                                            Dioxide (NO2), Temperature and
                                            Humidity,
                                        </strong> ensuring immediate
                                        detection of hazardous levels.</p>
                                </div>
                                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                    <h2 className='different'>Department-Specific Alerts</h2>
                                    <p>Customized alert thresholds based
                                        on the specific requirements and
                                        risks of each department, allowing for
                                        prompt and appropriate responses.</p>
                                </div>
                                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                    <h2 className='different'>Regulatory Compliance</h2>
                                    <p>Designed to meet and exceed
                                        healthcare regulations for gas exposure,
                                        ensuring a safe environment for both
                                        patients and healthcare professionals.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='label-frame' style={{ flex: 1 }}>
                            <h1>Benefits</h1>
                            <div className='label-frame-part'>
                                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                    <h2 className='different'>Enhanced Patient and Staff Safety</h2>
                                    <p>Mitigates the risk of exposure
                                        to harmful gases, significantly
                                        reducing potential health hazards.
                                    </p>
                                </div>
                                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                    <h2 className='different'>Operational Excellence</h2>
                                    <p>Improves the efficiency of hospital
                                        operations by minimizing the
                                        risk of equipment downtime
                                        due to hazardous gas levels.</p>
                                </div>
                                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                    <h2 className='different'>Compliance and Peace of Mind</h2>
                                    <p>Tailor the temperature settings
                                        to your specific comfort needs,
                                        allowing for flexibility and
                                        personalized comfort without
                                        compromising energy efficiency.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}
                className='dark-section'>
                <h1 style={{ alignSelf: 'flex-start' }}>Services</h1>
                <div className='section-head' style={{ gap: 12 }}>
                    <img style={{ width: '80%', filter: 'contrast(150%) brightness(120%)' }}
                        alt='' src={images.monitor} />
                    <div>
                        <Square style={{ color: 'var(--fg)', fontSize: 12 }} />
                        <p>INTEGRATED GAS LEVELS MONITORING SYSTEM</p>
                    </div>
                </div>
                <div style={{ alignSelf: 'flex-start', padding: '4rem' }}>
                    <h2 className='different'>Implementation and Impact</h2>
                    <ul style={{
                        listStyleType: 'none', fontSize: 18, marginTop: 20,
                        display: 'flex', flexDirection: 'column', gap: 16
                    }}>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Customized Implementation Plan.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Giving Orders Based on Gas Levels.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Compliance with Healthcare Regulations.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Making a Difference in Healthcare Facilities.</p>
                        </li>
                    </ul>
                </div>
                <img style={{ width: '80%', filter: 'contrast(120%) brightness(120%)' }}
                    alt='' src={images.laboratory} />
            </section>
        </>
    );
};

export default Acgms;