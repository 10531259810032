import images from '../images';
import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <div className="header">
            <img style={{ aspectRatio: '1 / .33' }} className="logo up" src={images.logo} alt="logo" />
            <div className="links">
                <NavLink to={'/'}>Home Page</NavLink>
                <NavLink to={'/about'}>About Us</NavLink>
                <NavLink to={'/portfolio'}>Our Portfolio</NavLink>
                <NavLink to={'/products-and-services'}>Products & Services</NavLink>
            </div>
            <img className="logo" src={images.rLogo} alt="logo" />
        </div>
    );
};

export default Header;