import images from '../images';
import icons from '../icons';
import './pages.css';

const Hardware = () => {
    return (
        <>
            <section>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '6rem' }}>
                    <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                        <div>
                            <h1 style={{ margin: '20px 0' }} className='different large-text'>
                                Hardware Customization & Development Services
                            </h1>
                            <h2>Tailored Solutions to Meet Your Unique Needs</h2>
                        </div>
                        <img style={{ height: '50%', aspectRatio: '1 / 1' }} alt='' src={icons.hardware2} />
                    </div>
                    <img style={{ width: '60%' }} alt='' src={images.hardware} />
                    <p style={{ width: '60%', fontSize: 20 }}>
                        At<strong className='different'> JSR AL SAMA, </strong>
                        we specialize in providing comprehensive hardware
                        customization and development services to address the specific needs
                        and requirements of our clients. Whether you’re seeking modifications
                        to existing hardware or the development of entirely new solutions, our
                        experienced team is dedicated to delivering innovative and reliable
                        products that exceed your expectations.
                    </p>
                </div>
            </section>
            <section className='dark-section'>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '4rem', marginTop: 20 }}>
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Expertise</h2>
                        <p>With years of experience in the hardware industry, our team consists of skilled
                            engineers, designers, and technicians who possess the knowledge and expertise
                            to tackle even the most complex hardware challenges. From electronic circuit
                            design to mechanical engineering, we have the capabilities to bring your ideas
                            to life.</p>
                    </div>
                    <img style={{
                        animationName: 'fade', width: '70%', alignSelf: 'center',
                        filter: 'contrast(150%) brightness(120%)'
                    }} alt='' src={images.processOverview} />
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Process</h2>
                        <p>Our proven development process ensures that your project is executed
                            efficiently and effectively, from initial concept to final delivery. We collaborate
                            closely with you at every stage, gathering requirements, providing regular
                            updates, and soliciting feedback to ensure that the final product meets your
                            exact specifications.</p>
                    </div>
                </div>
            </section>
            <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
                <h1 style={{ alignSelf: 'flex-start' }}>Services</h1>
                <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                    <h1 style={{ fontSize: 32, margin: '20px 0' }} className='different'>
                        Hardware Customization & Development Services
                    </h1>
                    <img style={{ height: '50%', aspectRatio: '1 / 1' }}
                        alt='' src={icons.hardware2} />
                </div>
                <img style={{ width: '90%', margin: '20px 0' }}
                    alt='' src={images.development} />
                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                    <h2 className='different'>Overview</h2>
                    <p>
                        Our hardware customization and development services offer a comprehensive
                        suite of solutions tailored to meet diverse client needs. This includes modifying
                        existing hardware to fulfill specific requirements, designing and developing
                        new hardware solutions from concept to production, and integrating custom
                        features to enhance performance and usability.</p>
                </div>
                <div className='section-head'>
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Customization Options</h2>
                        <p>
                            We understand that every project is unique, which is why we offer a wide range
                            of customization options to suit your specific needs. Whether it’s adjusting form
                            factors, integrating additional features, or optimizing performance, we work
                            closely with you to tailor our solutions to your requirements.</p>
                    </div>
                    <img alt='' src={icons.customization2} />
                </div>
            </section>
            <section className='dark-section over'>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '4rem', marginTop: 20 }}>
                    <div className='section-content' style={{
                        backgroundColor: 'var(--bg-secondary)',
                        padding: 24, borderRadius: 8
                    }}>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2 className='different'>Support and Maintenance</h2>
                            <p style={{ lineHeight: 1.5, fontSize: 18 }}>
                                Our commitment to customer
                                satisfaction extends beyond the
                                delivery of the final product. We provide
                                ongoing support and maintenance
                                services to ensure that your hardware
                                continues to perform optimally
                                throughout its lifecycle. Whether it’s
                                troubleshooting issues or implementing
                                updates, we’re here to help.</p>
                        </div>
                        <img style={{ borderRadius: 8 }} alt='' src={icons.support} />
                    </div>
                    <div className='section-head'>
                        <div className='section-icon'>
                            <img alt='' src={icons.project} />
                            <h3 className='different'>Project</h3>
                        </div>
                        <div className='section-icon'>
                            <img alt='' src={icons.feedback} />
                            <h3 className='different'>FeedBack</h3>
                        </div>
                        <div className='section-icon'>
                            <img alt='' src={icons.quality} />
                            <h3 className='different'>Quality Product</h3>
                        </div>
                    </div>
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Quality Assurance</h2>
                        <p style={{ width: '60%', fontSize: 18, lineHeight: 1.5 }}>Quality is at the forefront of everything we do. Our rigorous testing and
                            validation processes ensure that our hardware solutions meet the highest
                            standards of reliability, durability, and performance. We stand behind our
                            products and strive to exceed your expectations in terms of quality and
                            craftsmanship.</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Hardware;