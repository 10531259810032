import images from '../images';
import icons from '../icons';
import './pages.css';

const Chlorine = () => {
    return (
        <>
            <section>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '6rem' }}>
                    <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                        <div>
                            <h1 style={{ margin: '20px 0' }} className='different large-text'>
                                Monitor pH and chlorine
                            </h1>
                            <h2>Monitor pH and chlorine levels for water quality management</h2>
                        </div>
                        <img style={{ height: '50%', aspectRatio: '1 / 1' }} alt='' src={icons.chlorine2} />
                    </div>
                    <h1 className='different'>Monitor pH and Chlorine Levels for Optimal Water Quality</h1>
                    <img style={{ width: '60%' }} alt='' src={images.swimmingPool} />
                    <p style={{ width: '60%', fontSize: 20 }}>
                        At<strong className='different'> JSR AL SAMA, </strong>
                        we understand the importance of maintaining optimal
                        water quality in various applications, from swimming pools to industrial
                        processes. To address this critical need, we offer innovative solutions
                        leveraging IoT technology.
                    </p>
                </div>
            </section>
            <section className='dark-section'>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '4rem', marginTop: 20 }}>
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Overview</h2>
                        <p>Our solution focuses on continuous monitoring of pH and chlorine levels, two
                            critical parameters for assessing water quality. By accurately measuring these
                            levels, our clients can proactively manage their water systems and address any
                            deviations from optimal conditions.</p>
                    </div>
                    <img style={{
                        animationName: 'fade', width: '70%', alignSelf: 'center',
                        filter: 'contrast(150%) brightness(120%)'
                    }} alt='' src={images.monitorOverview} />
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Implementation</h2>
                        <p>Using customized hardware setups, pH and chlorine sensors are seamlessly
                            integrated into water systems. Real-time data from these sensors is collected
                            and processed to provide accurate insights into water quality.</p>
                    </div>
                </div>
            </section>
            <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
                <h1 style={{ alignSelf: 'flex-start' }}>Services</h1>
                <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                    <h1 style={{ fontSize: 32, margin: '20px 0' }} className='different'>
                        Monitor pH and Chlorine Levels for Optimal Water Quality
                    </h1>
                    <img style={{ height: '50%', aspectRatio: '1 / 1' }}
                        alt='' src={icons.chlorine2} />
                </div>
                <img style={{ width: '90%', filter: 'contrast(120%) brightness(120%)', margin: '20px 0' }}
                    alt='' src={images.monitorLight} />
                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                    <h2 className='different'>User Interface</h2>
                    <p>
                        Clients have convenient access to monitored data through our intuitive user
                        interface. This interface allows for easy visualization of pH and chlorine levels,
                        empowering users to make informed decisions regarding water quality
                        management.</p>
                </div>
                <div className='section-head'>
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Customization</h2>
                        <p>
                            We understand that every water
                            system is unique. Therefore, we
                            offer customization options to tailor
                            our solution to meet specific client
                            requirements, whether it’s integration
                            with existing infrastructure or
                            additional features.</p>
                    </div>
                    <img alt='' src={icons.customization2} />
                </div>
            </section>
            <section className='dark-section over'>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '4rem' }}>
                    <h1 className='different' style={{ fontSize: 32 }}>
                        Monitor pH and Chlorine Levels for Optimal Water Quality
                    </h1>
                    <div className='section-content' style={{
                        backgroundColor: 'var(--bg-secondary)',
                        padding: 24, borderRadius: 8
                    }}>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2 className='different'>Expert Support</h2>
                            <p style={{ lineHeight: 1.5, fontSize: 18 }}>
                                Our team of experienced professionals is
                                dedicated to providing comprehensive
                                support throughout the implementation
                                process, from initial setup to ongoing
                                maintenance and troubleshooting.</p>
                        </div>
                        <img style={{ borderRadius: 8 }} alt='' src={icons.support} />
                    </div>
                    <div className='label-frame' style={{ gap: 12 }}>
                        <h1 style={{ backgroundColor: 'var(--bg)', color: 'var(--fg)' }}>Benefits</h1>
                        <div className='label-frame-part'
                            style={{ alignItems: 'center', borderRight: '2px solid var(--fg)', flex: .5 }}>
                            <img alt='' src={icons.eye} />
                            <img alt='' src={icons.wifi} />
                            <img alt='' src={icons.gauge} />
                        </div>
                        <div className='label-frame-part' style={{ paddingLeft: 12 }}>
                            <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                <h2 className='different'>Continuous Monitoring</h2>
                                <p style={{ lineHeight: 1.5, fontSize: 18 }}>
                                    Our solution ensures round-the-clock
                                    monitoring of pH and chlorine levels,
                                    enabling prompt detection of any
                                    deviations from optimal conditions.</p>
                            </div>
                            <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                <h2 className='different'>Proactive Management</h2>
                                <p style={{ lineHeight: 1.5, fontSize: 18 }}>
                                    Empower clients with the ability to
                                    manage their water systems proactively,
                                    ensuring swift response to any deviations
                                    from desired conditions.</p>
                            </div>
                            <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                <h2 className='different'>Enhanced Efficiency</h2>
                                <p style={{ lineHeight: 1.5, fontSize: 18 }}>
                                    By automating data collection and
                                    analysis, our solution streamlines water
                                    quality management processes, saving
                                    time and resources.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Chlorine;