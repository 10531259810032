import ArrowIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';

const ScrollButton = ({ value }) => {
  return (
    <div className="button-container" style={{
      background: `conic-gradient(var(--fg) ${value}%, #d7d7d7 ${value}%)`
    }}>
      <div className='button' onClick={() => document.documentElement.scrollTop = 0}>
        <ArrowIcon style={{ fontWeight: 'bolder', fontSize: 32 }} />
      </div>
    </div>
  );
};

export default ScrollButton;