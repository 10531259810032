import images from "../images";


const Portfolio = () => {
    return (
        <>
            <section className="dark-section">
                <h1>Portfolio</h1>
                <h1 className="x-large-text">Our <span className="different">Portfolio</span></h1>
                <div style={{
                    width: '60%', fontSize: 20, lineHeight: 2, padding: 20,
                    display: 'flex', flexDirection: 'column', gap: 24
                }}>
                    <p>
                        Amidst the vibrant urban landscapes of the UAE a testament to luxury and
                        contemporary living <strong>JSR AL SAMA</strong> Information Systems Company pioneers the
                        next wave of digital transformation. Our vision is to revolutionize infrastructure
                        by integrating Advanced Environmental Control Systems, turning conventional
                        spaces into epitomes of intelligence and energy efficiency.
                    </p>
                    <h3 className="different">Intelligent Environmental Management Systems</h3>
                    <p>
                        A journey into our portfolio reveals a commitment to crafting intelligent
                        environments that think, adapt, and optimize themselves. Our systems aren’t just
                        smart; they’re sentient, responsive, and sustainable, ensuring that every facility
                        we touch evolves to become a benchmark for the future.
                    </p>
                    <img alt="" src={images.deal} />
                    <h3 className="different">Projects & Clients</h3>
                    <p>
                        Our extensive client list is a mirror to our versatility and the confidence vested in
                        us by industry leaders. Notable collaborations include:
                    </p>
                </div>
            </section>
            <section>
                <h1>Portfolio</h1>
                <div style={{ width: '100%', height: '100%', margin: '12px 0' }}>
                    <h1 className="different" style={{ padding: '16px 0' }}>Ministry of Human Resources</h1>
                    <div className="section-row">
                        <p style={{ fontSize: 20, lineHeight: 2, width: '50%' }}>Our <strong>ACGMS system</strong> is a cornerstone of energy
                            conservation and operational intelligence
                            across various Ministry edifices in the Emirates,
                            symbolizing our dedication to eco-conscious
                            innovation.<br /><br />
                            Our advanced <strong>ACGMS system</strong> has been
                            seamlessly integrated into Ministry facilities
                            across the UAE, including Dubai, Abu Dhabi,
                            Sharjah, Al Ain, Ras Al Khaimah, Ajman, , and
                            Fujairah.</p>
                        <img alt='' src={images.ministry} />
                    </div>
                    <h1 className="different" style={{ padding: '16px 0' }}>Sheikh Khalifa Hospital, Fujairah</h1>
                    <div className="section-row">
                        <p style={{ fontSize: 20, lineHeight: 2, width: '50%' }}>The bespoke turnkey RFID asset management
                            system we crafted for this esteemed institution
                            is a testament to our proficiency in addressing
                            the nuanced demands of the healthcare sector
                            with precision and foresight. Including <strong>Medical
                            Equipment (MEQ), Mechanical, Electrical, and
                            Plumbing (MEP) systems, Loose Furniture,
                            and Information Technology (IT)</strong></p>
                        <img style={{ width: '20%', aspectRatio: '1 / .5' }} alt='' src={images.hospital} />
                    </div>
                    <h1 className="different" style={{ padding: '16px 0' }}>Diverse Clientele</h1>
                    <div className="section-row">
                        <p style={{ fontSize: 20, lineHeight: 2, width: '50%' }}>Our impact extends across the tapestry of
                            industries from manufacturing to logistics,
                            and from retail to public services. Each
                            client benefits from our relentless pursuit of
                            excellence, as we deliver solutions that redefine
                            the parameters of operational efficiency and
                            environmental stewardship.</p>
                        <img style={{ width: '20%', aspectRatio: '1 / .5' }} alt='' src={images.group} />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Portfolio;