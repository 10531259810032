import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Root from './layout/Root';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import Acgms from './pages/ACGMS';
import Chlorine from './pages/Chlorine';
import Power from './pages/Power';
import Hardware from './pages/Hardware';
import Rfid from './pages/RFID';
import Portfolio from './pages/Portfolio';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root> <Home /> </Root>
    },
    {
      path: '/about',
      element: <Root> <About /> </Root>
    },
    {
      path: '/portfolio',
      element: <Root> <Portfolio /> </Root>
    },
    {
      path: '/products-and-services',
      element: <Root> <Products /> </Root>
    },
    {
      path: '/products-and-services/acgms',
      element: <Root> <Acgms /> </Root>
    },
    {
      path: '/products-and-services/ph-and-chlorine',
      element: <Root> <Chlorine /> </Root>
    },
    {
      path: '/products-and-services/power-consumption',
      element: <Root> <Power /> </Root>
    },
    {
      path: '/products-and-services/hardware-customization-and-development',
      element: <Root> <Hardware /> </Root>
    },
    {
      path: '/products-and-services/rfid-asset-tracking',
      element: <Root> <Rfid /> </Root>
    }
  ]);

  return (
    <RouterProvider router={router} />
  )
}

export default App
