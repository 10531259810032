import images from '../images';
import icons from '../icons';
import Circle from '@mui/icons-material/Circle';
import CircleOutlined from '@mui/icons-material/CircleTwoTone';
import './pages.css';

const Rfid = () => {
    return (
        <>
            <section>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '6rem' }}>
                    <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                        <div>
                            <h1 style={{ margin: '20px 0' }} className='different large-text'>
                                RFID Solutions & Asset Tagging
                            </h1>
                            <h2>Tailored Solutions to Meet Your Unique Needs</h2>
                        </div>
                        <img style={{ height: '50%', aspectRatio: '1 / 1' }} alt='' src={icons.remote2} />
                    </div>
                    <img style={{ width: '60%' }} alt='' src={images.office} />
                    <p style={{ width: '60%', fontSize: 20 }}>
                        At<strong className='different'> JSR AL SAMA, </strong>
                        we offer comprehensive RFID asset tracking solutions
                        designed to streamline asset management processes and improve
                        operational efficiency. With our cutting-edge RFID technology and
                        customizable software solutions, businesses can accurately track
                        and manage their assets, enhancing productivity, security, and costeffectiveness.
                    </p>
                </div>
            </section>
            <section className='dark-section compare'>
                <h1>Services</h1>
                <div className='section-head' style={{ alignItems: 'stretch', marginTop: 20, gap: '1rem' }}>
                    <div className='section-head' style={{ flexDirection: 'column', gap: '2rem' }}>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2 className='different'>Asset Tagging Services</h2>
                            <p>Our asset tagging services involve the identification
                                and labeling of assets with RFID tags or labels. We work
                                closely with clients to develop customized tagging
                                solutions tailored to their specific needs, including asset
                                categorization, serialization, and integration with existing
                                systems.</p>
                        </div>
                        <div className='label-frame' style={{ flex: .8 }}>
                            <h1 style={{ backgroundColor: 'var(--bg)', color: 'var(--fg)' }}>Key Features</h1>
                            <div className='label-frame-part'>
                                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                    <h2 className='different'>RFID Tags and Readers</h2>
                                    <p>We provide a range of RFID tags and
                                        readers suitable for various asset types
                                        and environments, including passive
                                        RFID technology.</p>
                                </div>
                                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                    <h2 className='different'>Customizable Software</h2>
                                    <p>Our customizable software solutions
                                        allow businesses to tailor their asset
                                        tracking system to meet specific
                                        requirements, including asset
                                        categorization, location mapping, and
                                        reporting capabilities.</p>
                                </div>
                                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                                    <h2 className='different'>Integration Capabilities</h2>
                                    <p>Our RFID asset tracking solutions
                                        seamlessly integrate with existing
                                        enterprise systems, such as inventory
                                        management software and ERP
                                        systems, to ensure data consistency and
                                        streamline workflow processes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='label-frame'>
                        <div className='label-frame-part'>
                            <div className='label-frame-field' style={{ textAlign: 'center', border: 'none' }}>
                                <img alt='' src={icons.software} />
                                <p style={{ fontWeight: 500, marginTop: 12, fontSize: 12 }}>RFID SOFTWARE</p>
                            </div>
                            <div className='label-frame-field' style={{ textAlign: 'center' }}>
                                <img alt='' src={icons.reader} />
                                <p style={{ fontWeight: 500, marginTop: 12, fontSize: 12 }}>RFID Readers</p>
                            </div>
                            <div className='label-frame-field' style={{ textAlign: 'center' }}>
                                <img alt='' src={icons.app} />
                                <p style={{ fontWeight: 500, marginTop: 12, fontSize: 12 }}>RFID Handheld Application</p>
                            </div>
                            <div className='label-frame-field' style={{ textAlign: 'center' }}>
                                <img alt='' src={icons.gateway} />
                                <p style={{ fontWeight: 500, marginTop: 12, fontSize: 12 }}>RFID Gateway & Antennas</p>
                            </div>
                            <div className='label-frame-field' style={{ textAlign: 'center' }}>
                                <img alt='' src={icons.middleware} />
                                <p style={{ fontWeight: 500, marginTop: 12, fontSize: 12 }}>
                                    RFID Middleware and Data Exchange Frame Work</p>
                            </div>
                            <div className='label-frame-field' style={{ textAlign: 'center' }}>
                                <img alt='' src={icons.tag} />
                                <p style={{ fontWeight: 500, marginTop: 12, fontSize: 12 }}>RFID Tags</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
                <h1 style={{ alignSelf: 'flex-start' }}>Services</h1>
                <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                    <h1 style={{ margin: '20px 0' }} className='different large-text'>
                        RFID Solutions & Asset Tagging
                    </h1>
                    <img style={{ height: '50%', aspectRatio: '1 / 1' }}
                        alt='' src={icons.remote2} />
                </div>
                <img style={{ width: '90%', margin: '20px 0' }}
                    alt='' src={images.corridor} />
                <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                    <h2 className='different'>Overview</h2>
                    <p>
                        Our asset tagging services involve the identification and labeling of assets
                        with RFID tags or labels. We work closely with clients to develop customized
                        tagging solutions tailored to their specific needs, including asset categorization,
                        serialization, and integration with existing systems.</p>
                </div>
            </section>
            <section className='dark-section'>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '4rem', marginTop: 20 }}>
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Implementation Process</h2>
                        <p>Our experienced team works closely with clients to assess their asset tracking
                            needs and develop a customized solution that aligns with their business
                            objectives. Our implementation process includes:</p>
                    </div>
                    <img style={{
                        animationName: 'fade', width: '70%', alignSelf: 'center',
                        filter: 'contrast(150%) brightness(120%)'
                    }} alt='' src={images.processOverview2} />
                    <div className='section-content-part' style={{ gridTemplateColumns: 'repeat(2, 1fr)', gap: 12 }}>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2 className='different'>Initial Consultation</h2>
                            <p>We conduct an in-depth analysis
                                of your asset management
                                requirements and goals to identify
                                the most suitable RFID technology
                                and software solution.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2 className='different'>Deployment and Training</h2>
                            <p>Our team manages the
                                installation and deployment of
                                the RFID infrastructure, providing
                                comprehensive training and support
                                to ensure a smooth transition and
                                user adoption.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2 className='different'>Solution Design</h2>
                            <p>Based on the assessment, we
                                design a tailored RFID asset tracking
                                system that addresses your specific
                                needs, including hardware selection,
                                software configuration, and
                                integration planning.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h2 className='different'>Ongoing Support</h2>
                            <p>We provide ongoing technical
                                support and maintenance
                                services to ensure the continued
                                performance and reliability of
                                your RFID asset tracking system,
                                including software updates,
                                troubleshooting, and system
                                optimization.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <h1>Services</h1>
                <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                    <h1 style={{ margin: '20px 0' }} className='different large-text'>
                        RFID Solutions & Asset Tagging
                    </h1>
                    <img style={{ height: '50%', aspectRatio: '1 / 1' }}
                        alt='' src={icons.remote2} />
                </div>
                <div className='label-frame' style={{ width: '90%', marginTop: 20 }}>
                    <h1>Benefits</h1>
                    <div className='section-content-part' style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                        <div className='section-icon' style={{
                            alignItems: 'flex-start',
                            padding: 12,
                            borderRight: '2px solid var(--fg)',
                            borderBottom: '2px solid var(--fg)'
                        }}>
                            <img alt='' src={icons.visibility} />
                            <h2 className='different'>Improved Visibility</h2>
                            <p style={{ width: '100%' }}>Enhance asset tracking to
                                monitor location, status, and
                                usage, minimizing risks of
                                loss, theft, and misplacement
                                effectively.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start', padding: 12 }}>
                            <img alt='' src={icons.wallet2} />
                            <h2 className='different'>Cost Savings</h2>
                            <p style={{ width: '100%' }}>Optimize asset utilization,
                                reduce asset downtime,
                                and minimize unnecessary
                                purchases or rentals, leading to
                                cost savings and improved ROI.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start', padding: 12 }}>
                            <img alt='' src={icons.gauge2} />
                            <h2 className='different'>Enhanced Efficiency</h2>
                            <p style={{ width: '100%' }}>Streamline asset management
                                processes, eliminate manual
                                data entry errors, and reduce
                                time spent searching for assets.</p>
                        </div>
                        <div className='section-icon' style={{
                            alignItems: 'flex-start',
                            padding: 12,
                            borderLeft: '2px solid var(--fg)',
                            borderTop: '2px solid var(--fg)'
                        }}>
                            <img alt='' src={icons.security} />
                            <h2 className='different'>Compliance and Security</h2>
                            <p style={{ width: '100%' }}>Maintain compliance with
                                regulatory requirements and
                                improve security measures by
                                tracking asset movements and
                                enforcing access controls.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='dark-section'>
                <h1>Services</h1>
                <div className='section-content' style={{ flexDirection: 'column', gap: '4rem', marginTop: 20 }}>
                    <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                        <h2 className='different'>Industries Served</h2>
                        <p>Our RFID asset tracking solutions cater to a
                            wide range of industries, including:</p>
                    </div>
                    <img style={{
                        animationName: 'fade', width: '100%', alignSelf: 'center',
                        filter: 'contrast(150%) brightness(120%)'
                    }} alt='' src={images.sections} />
                </div>
            </section>
            <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
                <h1 style={{ alignSelf: 'flex-start' }}>Services</h1>
                <div className='section-content' style={{ width: '90%', justifyContent: 'space-between' }}>
                    <h1 style={{ margin: '20px 0' }} className='different large-text'>
                        RFID Solutions & Asset Tagging
                    </h1>
                    <img style={{ height: '50%', aspectRatio: '1 / 1' }}
                        alt='' src={icons.remote2} />
                </div>
                <img style={{ width: '90%', margin: '20px 0' }}
                    alt='' src={images.hospitalRoom} />
                <h1 style={{ alignSelf: 'flex-start' }}>System Implementation Goals for Hospitals</h1>
                <div style={{ alignSelf: 'flex-start' }}>
                    <h1 className='different'>Asset Management Objectives</h1>
                    <ul style={{
                        listStyleType: 'none', fontSize: 18, marginTop: 20,
                        display: 'flex', flexDirection: 'column', gap: 16
                    }}>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <Circle style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Optimize and digitize workflows to enhance efficiency.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <Circle style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Increase the availability of medical equipment for patient care.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <Circle style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Manage and control the return on investment for medical equipment.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <Circle style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Improve the
                                <strong> HCAHPS (Hospital Consumer Assessment of Healthcare
                                    Providers and Systems) </strong>
                                rating to increase hospital revenue.</p>
                        </li>
                    </ul>
                </div>
            </section>
            <section className='dark-section'>
                <h1>Services</h1>
                <img style={{ marginTop: 20, filter: 'contrast(150%) brightness(120%)', width: '90%' }}
                    alt='' src={images.remoteControl} />
                <div style={{ alignSelf: 'flex-start', padding: '4rem' }}>
                    <h2 className='different'>Implementation and Impact</h2>
                    <ul style={{
                        listStyleType: 'none', fontSize: 18, marginTop: 20,
                        display: 'flex', flexDirection: 'column', gap: 16
                    }}>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Customized Implementation Plan.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Giving Orders Based on Gas Levels.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Compliance with Healthcare Regulations.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Making a Difference in Healthcare Facilities.</p>
                        </li>
                    </ul>
                </div>
                <div style={{ alignSelf: 'flex-start', padding: '4rem' }}>
                    <h2 className='different'>Implementation and Impact</h2>
                    <ul style={{
                        listStyleType: 'none', fontSize: 18, marginTop: 20,
                        display: 'flex', flexDirection: 'column', gap: 16
                    }}>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Customized Implementation Plan.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Giving Orders Based on Gas Levels.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Compliance with Healthcare Regulations.</p>
                        </li>
                        <li style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <CircleOutlined style={{ fontSize: 12, fontWeight: 'bold', color: 'var(--fg)' }} />
                            <p>Making a Difference in Healthcare Facilities.</p>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
};

export default Rfid;