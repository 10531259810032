import icons from '../icons';
import images from '../images';
import './pages.css';

const About = () => {
    return (
        <>
            <section className='gradient-inverse'>
                <h1>About Us</h1>
                <h1 className='large-text'>OUR <span className='different'>Values</span></h1>
                <div className='text-content'>
                    <p>Welcome to JSR ALSAMA TECHNICAL
                    INFORMATION, a pioneer in delivering
                    sustainable solutions that are reshaping
                    the future of energy management,
                    water quality, and asset tracking. With
                    a strong commitment to innovation,
                    efficiency, and sustainability, our
                    diverse range of services and products
                    is designed to meet the evolving needs
                    of our global clientele. Leveraging
                    cutting-edge technology and in-depth
                    expertise, we empower businesses
                    and communities to achieve their
                    operational and environmental goals.</p></div>
            </section>
            <section className='dark-section'>
                <h1>About Us</h1>
                <div className='section-content'>
                    <div className='section-content-part'>
                        <h1 style={{ fontWeight: 500 }}><span className='different'>At</span> JSR AL SAMA
                            <span className='different'> we empower
                                Businesses with
                                Innovative IT
                                Solutions.</span>
                        </h1>
                        <p className='medium-text' style={{ lineHeight: 1.5 }}>We believe in fostering collaboration,
                            empowering our team, and prioritizing
                            customer satisfaction. Our values drive
                            us to adapt to change, engage with our
                            community, and persist with resilience.
                            With passion for our work and a dedication
                            to serving our clients, we strive to make a
                            positive impact through technology.</p>
                    </div>
                    <div className='section-content-part' style={{ gap: 16 }}>
                        <div className='section-icon'>
                            <img alt='icon' src={icons.innovation} />
                            <div className='different' style={{ fontWeight: 'bold', fontSize: 20 }}>Innovation</div>
                        </div>
                        <div className='section-icon'>
                            <img alt='icon' src={icons.integrity} />
                            <div className='different' style={{ fontWeight: 'bold', fontSize: 20 }}>Integrity</div>
                        </div>
                        <div className='section-icon'>
                            <img alt='icon' src={icons.focus} />
                            <div className='different' style={{ fontWeight: 500, fontSize: 20 }}>Customer Focus</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='gradient'>
                <h1>About Us</h1>
                <h1 className='large-text'>OUR Values</h1>
                <img style={{ float: 'right' }} alt='' src={images.visionStatement} />
                <img style={{ float: 'right', marginTop: 20 }} alt='' src={images.missionStatement} />
            </section>
            <section className='dark-section'>
                <h1>About Us</h1>
                <h1 className='large-text'>Why <span className='different'>Us?</span></h1>
                <p style={{ width: '50%', margin: 30 }} className='medium-text'>
                    Choosing <strong>JSR AL SAMA TECHNICAL INFORMATION</strong>
                    means partnering
                    with a leader in sustainable technology solutions.<br />
                    Our unique approach
                    combines:</p>
                <div className='section-content' style={{ gap: '5%' }}>
                    <div className='section-icon'>
                        <img alt='icon' src={icons.solution} />
                        <img alt='icon' src={icons.sustainability} />
                        <img alt='icon' src={icons.customization} />
                        <img alt='icon' src={icons.support} />
                    </div>
                    <div className='section-icon' style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h3 className='different'>Innovative Solutions</h3>
                            <p>From the ACGMS to customized RFID tracking systems, our products stand at the forefront of technology.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h3 className='different'>Sustainability Focus</h3>
                            <p>We are committed to reducing environmental impact through efficient energy and resource management.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h3 className='different'>Customization and Flexibility</h3>
                            <p>Tailored solutions that precisely meet the specific requirements of our clients.</p>
                        </div>
                        <div className='section-icon' style={{ alignItems: 'flex-start' }}>
                            <h3 className='different'>Expert Support</h3>
                            <p>A dedicated team of professionals providing endto-end support, from initial consultation to ongoing maintenance.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default About;