import images from '../images';
import './pages.css';

const Home = () => {
  return (
    <>
      <section className='dark-section'>
        <h1>Intro</h1>
        <div className='intro'>
          <div>
            <h3>ENG. Mazen</h3>
            <span>GM, JSR AL SAMA</span>
          </div>
          <img alt='engineer' src={images.engMazen} />
        </div>
      </section>
      <section className='over'>
        <h2 style={{ fontWeight: 500 }}>Greetings</h2>
        <div className='section-head'>
          <h1 className='x-large-text'>G.M <span className='different'>Message</span></h1>
          <h1 className='quot different'>
            <span>&sbquo;</span>
            <span style={{ marginLeft: 6 }}>&sbquo;</span>
          </h1>
        </div>
        <div className='section-content'>
          <div className='section-content-part' style={{ lineHeight: 1.85 }}>
            <p>As the founder of JSR AL SAMA
            Information Technology Solutions,
            I am proud to lead a company
            that stands at the intersection of
            technology and sustainability. Our
            journey began with a vision to
            transform how businesses manage
            their resources, aiming to create
            a more sustainable and efficient
            world. Today, we continue to innovate, driven by our commitment to
            excellence, sustainability, and the
            success of our clients. Together,
            we are setting new standards for
            operational efficiency and environmental stewardship.<br/><br/>

            At JSR AL SAMA, we understand
            the critical role that technology
            plays in today’s business environment. That’s why we are constantly
            striving to push the boundaries of
            what is possible, leveraging the latest advancements in IT to help our</p></div>
          <div className='section-content-part' style={{ lineHeight: 1.5 }}>
          <p>clients stay ahead of the curve.
            Whether you’re a small startup or
            a large enterprise, we are here to
            partner with you on your journey
            to success. Our team is ready to listen to your unique challenges and
            develop customized solutions that
            address your specific needs and
            objectives.
            Thank you for considering JSR AL
            SAMA Information Technology
            Solutions as your trusted technology partner. We look forward to the
            opportunity to serve you and help
            you achieve your business goals.
            <br/><br/>Warm regards,</p>
            <div style={{ placeSelf: 'flex-end' }}>
              <img style={{ width: '100%' }} src={images.signature} alt='signature' />
              <h3>ENG.MAZIN</h3>
              <p>GM, JSR AL SAMA</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;